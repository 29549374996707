var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TButton',{staticClass:"mb-3",attrs:{"content":"Create a new post","to":"posts/new","icon":"cil-plus"}}),_c('TTableAdvance',{staticClass:"table-custom",attrs:{"items":_vm.list,"fields":_vm.fields,"store":"helper.posts","resource":"posts","enterable":"","reloadable":"","deletable":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.filterChange},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageText',{staticClass:"h5 mb-1",attrs:{"value":item.title,"messageOptions":{ truncate: 2 }}}),_c('TMessage',{attrs:{"content":item.description,"noTranslate":"","description":"","italic":"","truncate":2}})],1)]}},{key:"creator",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.creator)?_c('TMessageText',{attrs:{"value":item.creator.name}}):_c('TMessageText',{attrs:{"value":item.creator_id}})],1)]}},{key:"categories",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.categories),function(cat){return _c('CBadge',{key:cat.id,staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(cat.name)+" ")])}),1)]}},{key:"publish",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":item.publish ? 'success' : 'danger'}},[_c('TMessage',{attrs:{"content":item.publish ? 'Publish' : 'Draft'}})],1)],1)]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.created_at}})],1)]}},{key:"categories-filter",fn:function(){return [_c('SSelectPostCategory',{attrs:{"value":_vm.filter.categories,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "categories", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"creator-filter",fn:function(){return [_c('SSelectCustomer',{attrs:{"value":_vm.filter.creator_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "creator_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"title-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.title,"placeholder":"Title"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "title", $event)},_vm.filterChange]}})]},proxy:true},{key:"publish-filter",fn:function(){return [_c('SSelectStatus',{attrs:{"value":_vm.publish,"options":[
          {
            id: '',
            name: _vm.$t('All'),
          },
          {
            id: '1',
            name: _vm.$t('Publish'),
          },
          {
            id: '0',
            name: _vm.$t('Draft'),
          } ]},on:{"update:value":function($event){_vm.publish=$event},"change":_vm.filterChange}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }